<template>
    <v-snackbar v-model="props.show" :color="props.color" :timeout="props.timeout" location="top">
        {{ props.text }}
        <template v-slot:actions>
            <v-btn icon @click="closeSnackbar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
const props = defineProps({
    text: String,
    show: Boolean,
    color: String,
    timeout: {
        type: Number,
        default: 3000
    }
});

const emit = defineEmits(['update:show']);
const localShow = ref(props.show);

watch(
    () => props.show,
    (newVal) => {
        localShow.value = newVal;
        if (newVal) {
            setTimeout(() => {
                closeSnackbar();
            }, props.timeout);
        }
    }
);

const closeSnackbar = () => {
    emit('update:show', false);
};
</script>